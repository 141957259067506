import { Permission, PermissionUser } from 'src/app/app.models';
import { SmsService } from 'src/app/services/SmsService';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoginserviceService } from 'src/app/services/loginservice.service';
import { ToastrService } from 'ngx-toastr';
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from '@angular/fire/auth';
import { sendEmailVerification } from "firebase/auth";
import { Router } from '@angular/router';
import { DataSearchService } from './data-search.service';
import { LoggedUser } from '../app.models';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: "root",
})
export class AuthService {
  item: any;
  item2: any;
  userIn: any;
  message: string;

  public isLoggedIn = false;

  constructor(
    private toastr: ToastrService,
    public loginService: LoginserviceService,
    private fireauth: AngularFireAuth,
    private router: Router,
    private phone: SmsService,
    private dataSearchService: DataSearchService,
    private location: Location,
    public snackBar: MatSnackBar,
  ) { }

  // // login method
  login(email: string, password: string) {

    //Supprimer de la session et du local storage
    this.removeSessionAndLocalStorage();

    // //verifie existance de email dans la base mysql

    this.loginService.CheckExistEmail(email).subscribe(
      (data: any) => {
        this.item = data;
        //email existe
        // console.log(data);
        if (this.item.status == true) {
          //verif login et pass
          let obj = { email, password };
          this.loginService.getuser(obj).subscribe(
            (data: any) => {
              this.userIn = data;
              // on verifie si son email a été validé
              //console.log(data);
              if (this.userIn.user.emailVerified == 1) {

                if ((this.userIn.user.user_status == 0) || (this.userIn.user.user_status == null)) {
                  //on met les données dans la session
                  this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);
                  //On verifier s'il se connecté pour effectuer un réservation
                  if (this.dataSearchService.isConnected == false) {
                    this.dataSearchService.isConnected = true;
                    this.router.navigate(["latest-location-car"]);
                  } else {
                      this.router.navigateByUrl('/').then();

                  }
                }
                else {
                  this.toastr.error("Votre compte a été bloqué veiller contacter votre Administaeur");
                  this.logout();
                }

              } else {
                //cas ou son email n'a pas été validé , un email de validation est renvoyé
                this.toastr.success("Un email de validation vous a été envoyé");
                this.toastr.error("Vous n'avez pas validez notre email");
                // this.sendEmailForVarification(this.item.email);
                // on envoie le code de validation par email


                //on met email  dans la session
                let objEmail = {
                  'email': data.user.email,
                  'id': data.user.id,
                }

                localStorage.setItem("infoUser", JSON.stringify(objEmail))
                localStorage.setItem("token", JSON.stringify(data.token));

                let obj = { email };
                //console.log(obj);
                this.loginService.confirmeMailuser(obj).subscribe((data) => {
                  this.userIn = data;

                  this.router.navigate(["/validation-email"]);
                });
              }
            },
            (err: { [key: string]: any }) => {
              console.log(err)
              this.toastr.error(err.message);
              //this.toastr.error("veuillez reinitialiser votre mot de passe");
            }
          );
        } else {
          this.toastr.error("ce Compte n'existe pas");
          this.toastr.error("créer votre compte dès maintenant");
          this.router.navigate(["/create-utilisateurs"]);
        }
      },
      (err) => {
        this.toastr.error("Une erreur s'est produite, veillez contacter votre administrateur");
        this.router.navigate(["/create-utilisateurs"]);
      }
    );
  }

  // // login method
  loginPhone(email: any, password: any) {

    //Supprimer de la session et du local storage
    this.removeSessionAndLocalStorage();

    //verifie existance du numero dans la base mysql
    this.loginService.CheckExistPhone(email).subscribe(
      (data: any) => {
        this.item = data;
        //email existe

        if (this.item.status == true) {
          //verif login et pass
          let obj = {
            user_phone: email,
            password,
          };

          this.loginService.loginPhone(obj).subscribe(
            (data: any) => {
              this.userIn = data;
              //console.log(this.userIn.user.user_status);

              // on verifie si son email a été validé
              if (this.userIn.user.mobile_verified_at == 1) {


                if ((this.userIn.user.user_status == 0) || (this.userIn.user.user_status == null)) {
                  //on met les données dans la session
                  this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);

                  //On verifier s'il se connecté pour effectuer un réservation
                  if (this.dataSearchService.isConnected == false) {
                    this.dataSearchService.isConnected = true;
                    this.router.navigate(["latest-location-car"]);
                  } else {
                    // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                    //   duration: 6000,
                    //   verticalPosition: 'top',
                    //   panelClass: ['snackbar-danger']
                    // });
                    // this.router.navigateByUrl("/switch");
                    this.router.navigateByUrl('/').then();
                  }
                }
                else {
                  this.toastr.error("Votre compte a été bloqué veiller contacter votre Administrateur");
                  this.logout();
                }

              } else {

                let objPhone = {
                  'phone': data.user.user_phone,
                  'id': data.user.id,
                }

                localStorage.setItem("infoUser", JSON.stringify(objPhone));
                localStorage.setItem("token", JSON.stringify(data.token));
                let objs= {
                  id: data.user.id,
                  user_phone: email,
                };                
                this.toastr.error(
                  "Vous n'avez pas validez notre N° de telephone"
                );

                this.phone.phoneNum(objs).subscribe((data: any) => {
                    if(data.status) {
                      this.toastr.success("Un SMS de validation vous a été envoyé");
                      this.router.navigate(["/validation-telephone"]);
                    } else {
                      this.toastr.error(data.message)
                    }
                });
              }
            },
            (err) => {
              this.toastr.error("Votre mot de passe est incorrect");
              this.toastr.error("veuillez reinitialiser votre mot de passe");
            }
          );
        }

      },
      (err) => {
        this.toastr.error("ce Compte n'existe pas");
        this.toastr.error("créer votre compte dès maintenant");
        this.router.navigate(["/create-utilisateurs"]);
      }
    );
  }

  // // register method
  //register(email: string, password: string) {
  register(email: any, password: any) {

    // veririfions si c'est un numero de telephone
    if (Number.parseFloat(email)) {
      // verifion si le numero existe
      console.log("number");
      this.loginService.CheckExistPhone(email).subscribe((data: any) => {
        this.item = data;
        if (this.item.status == false) {
          console.log(" phone inexistant");
          const phoneVerified = 0;
          const providerId = "siloka.fr";
          const user_verify = 0;
          const uid = btoa(email);
          const statut = "particulier";
          let obj = {
            'user_phone': email,
            password,
            phoneVerified,
            providerId,
            uid,
            user_verify,
            statut,
          };
          // console.log(obj);
          this.loginService.createUtilisateurs2(obj).subscribe((data: any) => {
            this.toastr.success("utilisateur crée avec succes ");
             this.toastr.success("Un sms de validation vous a été envoyé");
            this.router.navigateByUrl("/login").then();
            this.sendEmailForVarification(email);
            //console.log(data);
          });
        } else {
          this.toastr.error("cette utilisateur  existe deja");
          this.router.navigateByUrl("/create-utilisateurs").then();
        }
      });
    } else {
      //console.log("mail");
      //verifier si email existe dans mysql
      this.loginService.CheckExistEmail(email).subscribe((data: any) => {
        this.item = data;
        if (this.item.status == false) {
          const emailVerified = 0;
          const providerId = "siloka.fr";
          const user_verify = 0;
          const uid = btoa(email);
          const statut = "particulier";
          let obj = {
            email,
            password,
            emailVerified,
            providerId,
            uid,
            user_verify,
            statut,
          };
          console.log(obj);
          this.loginService.createUtilisateurs(obj).subscribe((data: any) => {
            this.toastr.success("utilisateur crée avec succes ");
             this.toastr.success("Un email de validation vous a été envoyé");
            this.router.navigateByUrl("/login").then();
            this.sendEmailForVarification(email);
          });
        } else {
          this.toastr.error("cette utilisateur  existe deja");
          this.router.navigateByUrl("/create-utilisateurs").then();
        }
      });
    }
  }

  // serge le 25/03/2025
  registerEnterprise(email: any, password: any , telephone: any, nomenterprise: any , numerorccm: any , numerodfe: any , nomcontact: any) {

    // veririfions si c'est un numero de telephone
    if ((Number.parseFloat(email)) && (Number.parseFloat(telephone))) {
      // verifion si le numero existe
      console.log("email");
      console.log("telephone");
      this.loginService.CheckExistPhone(telephone).subscribe((data: any) => {
        this.item = data;
        if (this.item.status == true) {
          this.toastr.error("cette Email  existe deja");
          this.router.navigateByUrl("/create-enterprise").then();
          };
      });
    //verifier si email existe dans mysql
      this.loginService.CheckExistEmail(telephone).subscribe((data: any) => {
        this.item = data;
        if (this.item.status == true) {
          this.toastr.error("cette N° de telephone  existe deja");
          this.router.navigateByUrl("/create-enterprise").then();
          };
      });


    } else {

     // this.loginService.CheckExistEmail(email).subscribe((data: any) => {
        //this.item = data;
       // if (this.item.status == false) {
          const emailVerified = 0;
          const phoneVerified = 0;
          const providerId = "siloka.fr";
          const user_verify = 0;
          const statut = "entreprise";
          const uid = btoa(email);
          let obj = {
            email,
            password,
            emailVerified,
            phoneVerified,
            providerId,
            uid,
            user_verify,
            'user_phone': telephone,
            statut,
            nomenterprise, 
            numerorccm, 
            numerodfe, 
            nomcontact
          };
        //  console.log(obj);

           this.loginService.createEnterprise(obj).subscribe((data: any) => {
             this.toastr.success("Entreprise a été crée avec succes ");
          //   // this.toastr.success("Un email de validation vous a été envoyé");
             this.router.navigateByUrl("/login").then();
             this.sendEmailForVarification(email);
           });
       // } 
     // });
    }
  }


  // // forgot password
  forgotPassword(email: string) {
    this.fireauth.sendPasswordResetEmail(email).then(
      () => {
        this.router.navigate(["/varify-email"]);
      },
      (err) => {
        alert("Something went wrong");
      }
    );
  }

  // // email varification
  sendEmailForVarification(user: any) {
    console.log(user);

    sendEmailVerification(user)
      .then((res: any) => {
        // Your code is here
        console.log("mail envoyé", res);
      })
      .catch((error) => {
        // console.log("Email verification error", error);
      });
  }

  //sign in with google
  googleSignIn() {
    return this.fireauth
      .signInWithPopup(new GoogleAuthProvider())
      .then((res) => {
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.user?.uid));
        // nous initialison les parametre pour creer le compte sur la base de donnée
        let googleid = res.additionalUserInfo?.providerId;
        let uids = res.user?.uid;
        let password = [googleid, uids].join();
        const email = res.user?.email;
        const emailVerified = res.user?.emailVerified;
        const providerId = googleid;
        const uid = res.user?.uid;
        const statut = "particulier";
        let obj = { email, password, emailVerified, providerId, uid ,statut };
        // console.log(obj);
        // console.log(res);
        //nous recuperons email google
        let obj_email = { email: res.user?.email };
        //nous verifions son existance sur la bd
        this.loginService.CheckExistEmail(obj_email).subscribe(
          (data: any) => {
            this.item = data;
            console.log(data);
            if (this.item.status == true) {
              // on verifier que le compte n'est pas bloquer
              if ((this.item.user_status == 0) || (this.item.user_status == null)) {
                // le compte existe deja , on se connecte et on met les données dans la sessions
                this.loginService.getuser(obj).subscribe(
                  (data: any) => {
                    this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);
                    //On verifier s'il se connecté pour effectuer un réservation
                    if (this.dataSearchService.isConnected == false) {
                      this.dataSearchService.isConnected = true;
                      this.router.navigate(["latest-location-car"]);
                    } else {
                      // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                      //   duration: 6000,
                      //   verticalPosition: 'top',
                      //   panelClass: ['snackbar-danger']
                      // });
                      // this.router.navigateByUrl("/switch");

                      this.router.navigateByUrl("/").then();
                      this.sendEmailForVarification(email);
                      // this.location.back();
                    }

                  },
                  (error) => {
                    this.toastr.error("impossible de recupere les informations");
                    this.toastr.error("Mot de passe ou Login incorrect");
                    this.removeSessionAndLocalStorage();
                  }
                );
              } else {
                this.toastr.error("Votre compte a été bloqué veiller contacter votre Administrateur");
                this.logout();
              }
            } else {

              // le compte n'existe pas , nous le creeons
              this.loginService
                .createUtilisateurs(obj)
                .subscribe((data: any) => {
                  this.toastr.success("utilisateur crée avec succes ");
                  // email est deja valide parce qu'il se connecte avec le reseau google
                  this.loginService.getuser(obj).subscribe((data: any) => {
                    this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);
                    //On verifier s'il se connecté pour effectuer un réservation
                    if (this.dataSearchService.isConnected == false) {
                      this.dataSearchService.isConnected = true;
                      this.router.navigate(["latest-location-car"]);
                    } else {
                      this.router.navigateByUrl("/").then();
                    }

                  });
                });
            }
          },
          (error) => {
            this.removeSessionAndLocalStorage();
            this.toastr.error("impossible de verifier email");
          }
        );
      });
  }

  //sign in with facebook

  facebookSignIn() {
    return this.fireauth
      .signInWithPopup(new FacebookAuthProvider())
      .then((res) => {
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.user?.uid));
        // nous initialison les parametre pour creer le compte sur la base de donnée
        let googleid = res.additionalUserInfo?.providerId;
        let uids = res.user?.uid;
        let password = [googleid, uids].join();
        const email = res.user?.email;
        const emailVerified = res.user?.emailVerified;
        const providerId = googleid;
        const uid = res.user?.uid;
        const statut = "particulier";

        let obj = { email, password, emailVerified, providerId, uid, statut };
        // console.log(obj);
        // console.log(res);
        //nous recuperons email google
        let obj_email = { email: res.user?.email };
        //nous verifions son existance sur la bd
        this.loginService.CheckExistEmail(obj_email).subscribe((data: any) => {
          this.item = data;
          if (this.item.status == true) {
            // verifier si le compte est bloquer
            if ((this.item.user_status == 0) || (this.item.user_status == null)) {
              // le compte existe deja , on se connecte et on met les données dans la sessions
              this.loginService.getuser(obj).subscribe((data) => {
                // console.log(obj);
                if (res.user?.emailVerified == true) {
                  this.loginService
                    .updateEmailVerified(res.user.email, res.user.emailVerified)
                    .subscribe((data: any) => { });
                  this.userIn = data;
                  if ((this.userIn.status = true)) {
                    //recuperons les donnees profil dans la base et mettons dans la sessions
                    let obj = { email, password };
                    this.loginService.getuser(obj).subscribe((data: any) => {
                      // console.log(data);
                      this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);
                      // sessionStorage.setItem("user", JSON.stringify(data));
                      // localStorage.setItem("user", JSON.stringify(data));
                      this.router.navigateByUrl('/').then();
                      // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                      //   duration: 6000,
                      //   verticalPosition: 'top',
                      //   panelClass: ['snackbar-danger']
                      // });
                      // this.router.navigateByUrl("/switch");
                    });
                    //console.log(obj);
                  }
                } else {
                  // renvoyer le mail , si elle n'a pas encore été valider
                  this.toastr.success(
                    "Un email de validation vous a été renvoyé"
                  );
                  this.toastr.error("Vous n'avez pas validez notre email");
                  this.sendEmailForVarification(res.user);
                  this.router.navigate(["/login"]);
                }
              });
            } else {
              this.toastr.error("Votre compte a été bloqué veiller contacter votre Administrateur");
              this.logout();
            }
          } else {
            // le compte n'existe pas , nous le creeons
            this.loginService.createUtilisateurs(obj).subscribe((data: any) => {
              // pour facebook email doit etre valider
              this.sendEmailForVarification(res.user);
              this.toastr.success("utilisateur crée avec succes ");
              this.toastr.success("Un email de validation vous a été envoyé");
              //this.router.navigate(["/login"]);

              this.loginService.getuser(obj).subscribe((data: any) => {
                //this.router.navigateByUrl("/compte-utilisateur").then();
                this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);
                this.router.navigateByUrl("/").then();
                // this.location.back();
                // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                //   duration: 6000,
                //   verticalPosition: 'top',
                //   panelClass: ['snackbar-danger']
                // });
                // this.router.navigateByUrl("/switch");
              });
            });
          }
        });
      });
  }
  //sign in with apple

  appleSignIn() {
    return this.fireauth
      .signInWithPopup(new OAuthProvider("apple.com"))
      .then((res) => {
        console.log(res);
        localStorage.setItem("user", JSON.stringify(res.user?.uid));
        // nous initialison les parametre pour creer le compte sur la base de donnée
        let googleid = res.additionalUserInfo?.providerId;
        let uids = res.user?.uid;
        let password = [googleid, uids].join();
        const email = res.user?.email;
        const emailVerified = res.user?.emailVerified;
        const providerId = googleid;
        const uid = res.user?.uid;
        const statut = "particulier";
        let obj = { email, password, emailVerified, providerId, uid, statut };
        // console.log(obj);
        // console.log(res);
        //nous recuperons email google
        let obj_email = { email: res.user?.email };
        //nous verifions son existance sur la bd
        this.loginService.CheckExistEmail(obj_email).subscribe(
          (data: any) => {
            this.item = data;
            console.log(data);
            if (this.item.status == true) {
              if ((this.item.user_status == 0) || (this.item.user_status == null)) {
                // le compte existe deja , on se connecte et on met les données dans la sessions
                this.loginService.getuser(obj).subscribe(
                  (data: any) => {

                    this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);

                    //On verifier s'il se connecté pour effectuer un réservation
                    if (this.dataSearchService.isConnected == false) {
                      this.dataSearchService.isConnected = true;
                      this.router.navigate(["latest-location-car"]);
                    } else {
                      // this.router.navigateByUrl("/compte-utilisateur").then();
                      this.router.navigateByUrl("/").then();
                      // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                      //   duration: 6000,
                      //   verticalPosition: 'top',
                      //   panelClass: ['snackbar-danger']
                      // });
                      // this.router.navigateByUrl("/switch");
                    }

                  },
                  (error) => {
                    this.toastr.error("impossible de recupere les informations");
                    this.toastr.error("Mot de passe ou Login incorrect");
                    this.removeSessionAndLocalStorage();
                  }
                );
              } else {
                this.toastr.error("Votre compte a été bloqué veiller contacter votre Administrateur");
                this.logout();
              }
            } else {

              // le compte n'existe pas , nous le creeons
              this.loginService
                .createUtilisateurs(obj)
                .subscribe((data: any) => {
                  this.toastr.success("utilisateur crée avec succes ");

                  // email est deja valide parce qu'il se connecte avec le reseau google
                  this.loginService.getuser(obj).subscribe((data: any) => {

                    this.addSessionAndLocalSTorage(data.user, data.token, data.authorizationUser);

                    //On verifier s'il se connecté pour effectuer un réservation
                    if (this.dataSearchService.isConnected == false) {
                      this.dataSearchService.isConnected = true;
                      this.router.navigate(["latest-location-car"]);
                    } else {
                      // this.router.navigateByUrl("/compte-utilisateur").then();
                      this.router.navigateByUrl("/").then();
                      // this.snackBar.open("Selectionner votre type de compte", "Fermer", {
                      //   duration: 6000,
                      //   verticalPosition: 'top',
                      //   panelClass: ['snackbar-danger']
                      // });
                      // this.router.navigateByUrl("/switch");
                    }

                  });
                });
            }
          },
          (error) => {
            this.removeSessionAndLocalStorage();
            this.toastr.error("impossible de verifier email");
          }
        );
      });
  }
  /**
   * Cette methode permet de verifier si les informations de l'utilisateur se trouve 
   * toujours dans la session ou dans le local storage
   * 
   * @returns 
   */
  getLoggedUser(): LoggedUser {

    let local = JSON.parse(localStorage.getItem("user")!);
    let session = JSON.parse(sessionStorage.getItem("user")!);

    if (local || session) {

      this.isLoggedIn = true;

      return { 'status': true, currentTest: 'user' };

    } else {

      if (JSON.parse(localStorage.getItem("infoUser")!)) {

        this.isLoggedIn = false;

        return { 'status': true, currentTest: 'infoUser' };

      } else {

        this.isLoggedIn = false;

        return { 'status': false, currentTest: 'user' };

      }


    }

  }

  /**
   * Nous vérifions, si les informations de l'utilisateur se trouve dans le 
   * LocalStorage
   */
  getUserOfLocalStorage() {

    let local = JSON.parse(localStorage.getItem("user")!);

    if (local) {

      return { 'status': true };

    } else {

      return { 'status': false };

    }
  }

  /**
  * Cette methode permet de vérifier si le profil de l'utilisateur est complet 
  */
  verifieProfilOfUser() {

    let afficheAddcars: boolean | undefined;
    let afficheCoordonnees: boolean | undefined;
    let affichepermis: boolean | undefined;
    let affichecni: boolean | undefined;

    let user = JSON.parse(localStorage.getItem("user")!)

    //permis
    const driver_licenses_number = user.driver_licenses_number;

    // nom , prenom , telephone et date de naissance
    const user_name = user.user_name;

    // cni
    const cni_number = user.cni_number;

    // drive OK permis OK cni OK
    if ((driver_licenses_number !== null) && (user_name !== null) && (cni_number !== null)) {
      afficheAddcars = true;
    }

    //drive OK cni OK permis KO 
    if ((driver_licenses_number !== null) && (cni_number !== null) && (user_name == null)) {
      afficheCoordonnees = false;
    }

    //drive OK cni KO permis 0K 
    if ((driver_licenses_number !== null) && (cni_number == null) && (user_name !== null)) {
      affichecni = false;
    }

    //drive KO cni 0K permis 0K
    if ((driver_licenses_number == null) && (cni_number !== null) && (user_name !== null)) {
      affichepermis = false;
    }

    //drive kO cni k0 permis k0
    if ((driver_licenses_number == null) && (cni_number == null) && (user_name == null)) {
      affichepermis = false;
      affichecni = false;
      afficheCoordonnees = false;
    }

    //drive OK cni k0 permis k0
    if ((driver_licenses_number !== null) && (cni_number == null) && (user_name == null)) {
      affichecni = false;
      afficheCoordonnees = false;
    }

    //drive KO cni Ok permis k0
    if ((driver_licenses_number == null) && (cni_number !== null) && (user_name == null)) {
      affichepermis = false;
      afficheCoordonnees = false;
    }

    //drive KO cni kO permis Ok
    if ((driver_licenses_number == null) && (cni_number == null) && (user_name !== null)) {
      affichepermis = false;
      affichecni = false;
    }



    return { 'afficheAddcars': afficheAddcars, 'afficheCoordonnees': afficheCoordonnees, 'affichepermis': affichepermis, 'affichecni': affichecni }

  }
  
  checkCni(): boolean {

    let user = JSON.parse(localStorage.getItem("user")!);

    return user.cni_number == null || user.cni_number == undefined ? false : true;
    

  }


  private addSessionAndLocalSTorage(user: object, token: string, Permission: Array<object>) {

    sessionStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("permission", JSON.stringify(Permission));
    localStorage.setItem("token", JSON.stringify(token));
  }

  public removeSessionAndLocalStorage() {

    localStorage.removeItem("user");
    localStorage.removeItem("infoUser");
    localStorage.removeItem("token");
    localStorage.removeItem("permission");
    sessionStorage.removeItem("user");
    this.isLoggedIn = false;
    sessionStorage.clear();
    localStorage.clear();
    window.sessionStorage.clear();

  }

  logoutFront() {

    this.removeSessionAndLocalStorage();

    this.router.navigate(['/login']);

  }

  logout() {

    this.loginService.logout().subscribe(res => { });

    this.logoutFront();

  }

  verifyPermission(data: any) {

    console.log(data);

    const storage = localStorage.getItem("facebook_auth");
    const userStorage = localStorage.getItem("user");
    let permissionStorage = localStorage.getItem("permission");
    let permissions: Array<PermissionUser> = [];

    if (userStorage) {
      this.userIn = JSON.parse(userStorage);
    }

    if (storage) {
      this.userIn = JSON.parse(storage);
    }

    if (permissionStorage) {
      permissions = JSON.parse(permissionStorage);
    }

    let stat = false;

    //console.log(permissions);
    if (this.userIn.user_profil === "SUPER_ADMIN") {
      return true;
    }
    else {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (permissions.find(elt => elt.permission_id == data[i])) {
            stat = stat || true;
          }
          else {
            stat = stat || false;
          }
        }
        return stat;
      }
      else {
        return false;
      }
    }

  }

  redirectUserProfil() {
    const storage = localStorage.getItem("facebook_auth");
    const userStorage = localStorage.getItem("user");

    if (userStorage) {
      this.userIn = JSON.parse(userStorage);
    }

    if (storage) {
      this.userIn = JSON.parse(storage);
    }

    if (this.userIn.user_profil == "ADMIN" || this.userIn.user_profil == "SUPER_ADMIN") {
      this.router.navigateByUrl("/admin/users/view?id=" + this.userIn.id).then();
    }
    else {
      this.router.navigateByUrl('/').then();
    }
  }

  /*loginRedirection(profilUser:string):void{
    if(profilUser==="")
    this.router.navigate(['/login']);
  }*/
}