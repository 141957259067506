<div fxLayout="row wrap">
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{sumRenting}} {{currency}}</h2>         
            <p>Total Paiement</p>            
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-orange tile p-2">
            <mat-icon>trending_up</mat-icon> 
            <h2>{{sumTransfer}} {{currency}}</h2>         
            <p>Total Virement</p> 
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-red tile p-2"> 
            <mat-icon>thumb_up_alt</mat-icon> 
            <h2>{{currentAmount}} {{currency}}</h2>         
            <p>Montant encaisse</p>            
        </mat-card>
    </div>
    <!-- <div fxFlex="50" fxFlex.xl="20" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{gain}} {{currency}}</h2>         
            <p>Total des Bénefices pour GalsenAuto</p>             -->
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-brown tile p-2">
            <mat-icon>monetization_on</mat-icon> 
            <h2>{{sumAsset}} {{currency}}</h2>         
            <p>Montant avoirs</p>  
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">        
        <mat-card class="gradient-green tile p-2"> 
            <mat-icon>trending_down</mat-icon> 
            <h2>{{waveBalance}} {{currency}}</h2>         
            <p>Solde Wave</p>            
        </mat-card>
    </div>
    <div fxFlex="50" fxFlex.xl="16.6" fxFlex.lg="16.6" fxFlex.md="33.3" fxFlex.sm="33.3" class="p-2">
        <mat-card class="gradient-orange tile p-2">
            <mat-icon>trending_down</mat-icon> 
            <h2>{{orangeBalance}} {{currency}}</h2>         
            <p>Solde Orange</p> 
        </mat-card>
    </div>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center" class="p-2" class="search">
    <div fxLayout="row">
        <mat-button-toggle-group #controls="matButtonToggleGroup" appearance="legacy">            

            <mat-button-toggle value="search">
                <mat-icon>search</mat-icon>
            </mat-button-toggle>           
        </mat-button-toggle-group>            
    </div>
    <form class="user-search" [class.show]="controls.value == 'search'">
        <mat-form-field class="user-search-input">
            <input matInput placeholder="Recherche un Virement..." (keyup)="applyFilter($event)" name="search">
        </mat-form-field>
    </form>
</div>
<mat-card class="m-2 p-0">

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
        <div fxFlex="100" fxFlex.gt-sm="33" fxFlex.sm="33">
            
            <button  mat-icon-button  color="primary" matTooltip="Affichage en Grille" (click)="changeViewType('grid')">
                <span><mat-icon>grid_view</mat-icon></span>
            </button>
            <button  mat-icon-button  color="primary" matTooltip="Affichage en Liste" (click)="changeViewType('list')">
                <span><mat-icon>format_list_bulleted</mat-icon></span>
            </button>
        </div>
    </div>

    <div class="table-wrapper" *ngIf="viewType==='list'"> 
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
        
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let transfer; let i=index"> #{{i+1}} </td>
            </ng-container>  
    
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.transfer_amount}} {{currency}}</td>
            </ng-container>  

             <!-- Type Column -->
             <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Motif </th>
                <td mat-cell *matCellDef="let transfer"> 
                    <span *ngIf="transfer.transfer_type == 'RENTING';else elseBlock">Location</span>
                    <ng-template #elseBlock>Avoir</ng-template>
                </td>
            </ng-container> 

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let transfer">
                    <span *ngIf="transfer.transfer_status == 'EN_ATTENTE'" style="color: blue;">En attente de validation</span>
                    <span *ngIf="transfer.transfer_status == 'VALIDER'" style="color: rgb(3, 154, 3);">Validé</span>
                    <span *ngIf="transfer.transfer_status == 'REJETER'" style="color:red;">Rejeté</span>
                    <span *ngIf="transfer.transfer_status == 'EFFECTUER'" style="color: rgb(190, 191, 154);">Effectué</span> 
                </td>
            </ng-container> 

            <!-- Table user Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.user_name}} {{transfer.user_surname}}</td>
            </ng-container> 

            <!-- Table Number Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Numéro de compte  </th>
                <td mat-cell *matCellDef="let transfer"> {{transfer.numero_compte}}</td>
            </ng-container> 
    
            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let transfer">{{transfer.created_at | date:"dd MMMM, yyyy 'à' HH:mm" }}</td>
            </ng-container>
            
            <!-- View Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let transfer">
                    <button *ngIf="transfer.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="primary" matTooltip="Approuver la demande de virement" (click)="changeStatus(transfer.id, 'VALIDER')">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button *ngIf="transfer.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="warn" matTooltip="Rejeter la demande de virement" (click)="changeStatus(transfer.id, 'REJETER')">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button *ngIf="transfer.transfer_status == 'VALIDER'"  mat-icon-button  color="primary" matTooltip="Effectuer le virement" (click)="choosePaymentMethod(transfer)">
                        <mat-icon>account_balance_wallet</mat-icon>
                    </button>
                </td>
            </ng-container> 
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let transfer; columns: displayedColumns;"></tr>
        </table> 
        <p *ngIf="dataSource && dataSource.data.length === 0" class="py-5 mb-0 text-center">No data available</p> 
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center" class="w-100 p-2" *ngIf="viewType==='grid'">
        <mat-card class="menu-item" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
            [ngClass]="[viewType + '-item', 'column-'+column]" fxFlex.gt-sm="30" fxFlex.sm="30"  *ngFor="let item of virements | paginate: { itemsPerPage: 10, currentPage: p }">
           
            <div fxFlex="100" class="p-3">
                <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                    <mat-card-content class="mb-0">
                        <mat-icon class="icon-title">swap_horiz</mat-icon>
                        <h1 class="title">
                            {{item.transfer_amount}} {{currency}}
                        </h1>
                        <div> 
                            <div class="features">
                                <span *ngIf="item.transfer_status == 'EN_ATTENTE'" style="color: blue;">En attente de validation</span>
                                <span *ngIf="item.transfer_status == 'VALIDER'" style="color: rgb(3, 154, 3);">Validé</span>
                                <span *ngIf="item.transfer_status == 'REJETER'" style="color:red;">Rejeté</span>
                                <span *ngIf="item.transfer_status == 'EFFECTUER'" style="color: rgb(190, 191, 154);">Effectué</span>
                            </div>
                            <div class="features">
                                <p>Client: {{item.user_name}} {{item.user_surname}} <br>
                                    Motif: <span *ngIf="item.transfer_type == 'RENTING';else elseBlock">Location</span>
                                    <ng-template #elseBlock>Avoir</ng-template>
                                </p>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="m-0 p-0">
                        <div class="divider mt-1">
                            <span class="text-muted text-center">
                                {{item.created_at| date:"dd MMMM, yyyy 'a' HH:mm" }}
                            </span>
                            <button *ngIf="item.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="primary" matTooltip="Approuver la demande de virement" (click)="changeStatus(item.id, 'VALIDER')">
                                <mat-icon>check</mat-icon>
                            </button>
                            <button *ngIf="item.transfer_status == 'EN_ATTENTE'" mat-icon-button  color="warn" matTooltip="Rejeter la demande de virement" (click)="changeStatus(item.id, 'REJETER')">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <button *ngIf="item.transfer_status == 'VALIDER'"  mat-icon-button  color="primary" matTooltip="Effectuer le virement" (click)="choosePaymentMethod(item)">
                                <mat-icon>account_balance_wallet</mat-icon>
                            </button>
                        </div>
                    </mat-card-actions>
                </div>
            </div>
        </mat-card>
        <p *ngIf="virements && virements.length === 0" class="py-5 mb-0 text-center">Pas de données disponibles</p> 

    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="w-100 p-2">
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="20">
            <button  mat-icon-button  color="primary" matTooltip="Rafraichir" (click)="refresh()">
                <span><mat-icon>refresh</mat-icon> Mise à jour</span>
            </button>
        </div>
        
        <div fxFlex="100" fxFlex.gt-sm="80" fxFlex.sm="80" *ngIf="viewType==='grid'">
            <pagination-controls (pageChange)="p = $event" style="text-align: end;"></pagination-controls>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" class="{{viewType==='list' ? 'block' : 'hidden'}}"></mat-paginator>
    </div>
</mat-card>