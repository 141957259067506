<!-- Valderane, 30/03/2025 -->
<h2 matDialogTitle>Moyens de paiement</h2>
<mat-dialog-content>
    <h4 class="first-text">{{title}}</h4>
    <div fxLayout="row wrap" fxLayoutAlign="space-around" *ngIf="data.key == 'payment' && paymentMethod == null">
      <div fxFlex="100" fxFlex.gt-xs="50" ngClass.xs="mt-2" (click)="checkMethodPayment('Stripe')">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="method">
              <img class="image-method" src="../../../../../assets/images/method_payment/carte-bancaire.jpg">
            </div>          
          </div>
      </div>
      <div *ngIf="data.key == 'payment'" fxFlex="100" fxFlex.gt-xs="50" ngClass.xs="mt-2" (click)="checkMethodPayment('Asset')">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="method">
            <img class="image-method" src="../../../../../assets/images/method_payment/avoir.png">
          </div>          
        </div>
      </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-around" *ngIf="data.key == 'transfer' && numeroCompte">
      <div fxFlex="100" fxFlex.gt-xs="50" ngClass.xs="mt-2" (click)="checkMethodPayment('Stripe')">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="method">
              <img class="image-method" src="../../../../../assets/images/method_payment/carte-bancaire.jpg">
            </div>          
          </div>
      </div>
      <div *ngIf="data.key == 'payment'" fxFlex="100" fxFlex.gt-xs="50" ngClass.xs="mt-2" (click)="checkMethodPayment('Asset')">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="method">
            <img class="image-method" src="../../../../../assets/images/method_payment/avoir.png">
          </div>          
        </div>
      </div>
    </div>

    <!-- <br>
    
    <div *ngIf="numeroCompte && numeroCompte.numero_compte_operateur == null">
      <h3 style="color: red;">L'utilisateur n'a pas encore configuré son porte monnaie électronique</h3>
    </div>
    <div *ngIf="data.key == 'payment' && paymentMethod != null" style="margin-top: -40px;">
      <div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 10px;">
        <div class="method">
          <img class="image-method" src="../../../../../assets/images/method_payment/Orange-Money.png">
        </div>
      </div>
      <div>
        <p style="text-align: center;">
          Vous devez pour cela obtenir un code de paiement : <br>
          - Composez le <strong>#144#391*code secret OM#</strong> puis appuie sur la touche <strong>appel</strong> pour générer un code de recharge<br>
          - Vous recevez ensuite un SMS avec un code de paiement utilisable pendant 5 minutes.
        </p>
      </div>
      <form [formGroup]="form" (ngSubmit)="savePaymentOrange()" autocomplete="off">
            
        <div fxLayout="row" fxLayoutAlign="center">
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Code réçu par SMS</mat-label>
                    <input matInput formControlName="code" required>
                    <mat-error *ngIf="form.controls.code.errors?.required">
                      Champ obligatoire
                    </mat-error>
                    <mat-error *ngIf="form.controls.code.errors?.pattern">Veuillez saisir une chaîne de six(06) chiffre</mat-error>
                </mat-form-field>
            </div>
        </div>
        <br>
        <div fxLayout="row wrap" fxLayoutAlign="center">
            <div fxFlex="100" fxFlex.gt-sm="20" class="px-2" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <div class="text-center">
                    <button mat-raised-button color="primary" type="submit"> Valider</button>
                </div>
            </div>
        </div>
        
      </form>
    </div> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" matDialogClose>Annuler</button>
</mat-dialog-actions>