import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { DataSearchService } from 'src/app/services/data-search.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { RentingService } from 'src/app/services/renting.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Country, Hour, Renting } from 'src/app/app.models';
import { UserServiceService } from 'src/app/services/user-service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginserviceService } from 'src/app/services/loginservice.service';



@Component({
  selector: 'app-latest-location-form',
  templateUrl: './latest-location-form.component.html',
  styleUrls: ['./latest-location-form.component.scss']
})
export class LatestLocationFormComponent implements OnInit, OnDestroy {

  //subcription
  rentingSubscription: Subscription;
  userSubscription: Subscription;

  //Boolean;
  rentingCreated: boolean
  userUpdated: boolean

  public form!: UntypedFormGroup;

  public hoursBefore: Array<Hour>;

  public hoursAfter: Array<Hour>;

  public countries: Array<Country>;

  public user: any;
  idCars: any;
  public item: any;

 // phonePattern = "^((\\+237-?)|(\\+33-?)|(\\+221-?))[8|9|6|7][0-9]{8}$";
//indicatif France et Dom Tom 262,689,687,681,508,269,594,596,590,33
phonePattern = "^((\\+262-?)|(\\+689-?)|(\\+687-?)|(\\+681-?)|(\\+508-?)|(\\+269-?)|(\\+594-?)|(\\+596-?)|(\\+590-?)|(\\+33-?))[8|9|6|7][0-9]{8}$";

  userphone: boolean;

  public maxDate: Date;

  constructor(public formBuilder: UntypedFormBuilder,
    private appService: AppService,
    public loginService: LoginserviceService,
    public dataSearchService: DataSearchService,
    private toastr: ToastrService,
    private rentingService: RentingService,
    private datePipe: DatePipe,
    private router: Router,
    private userService: UserServiceService,
    private spinner: NgxSpinnerService) {
    this.countries = this.appService.getCountries();
    this.hoursBefore = this.appService.getHoursBefore();
    this.hoursAfter = this.appService.getHoursAfter();
  }

  ngOnInit(): void {

    this.initProperty();

  }

  private initProperty() {

    // this.user = JSON.parse(sessionStorage.getItem("user")!)

    this.userSubscription = this.userService.getUserFind(JSON.parse(sessionStorage.getItem("user")!).id).subscribe((user: any) => {

      // console.log(user)

      this.user = user[0];

      this.initForm();

    })

    this.rentingCreated = false;

    this.userUpdated = false;

    //Date de naissance Max
    var d = new Date();
    let year = d.getFullYear();
    let diff = Number(year - 17);
    this.maxDate = new Date(Number(diff - 1)+'-12-31')

  }


  initForm() {
    this.form = this.formBuilder.group({
      hourStart: [null, Validators.required],
      hourEnd: [null, Validators.required],
      message: [null],
      surname: [this.user['user_surname'], Validators.required],
      name: [this.user['user_name'], Validators.required],
      date: [this.user['user_datenais'], Validators.required],
      phone: [this.user['user_phone'], Validators.compose([Validators.required, Validators.pattern(this.phonePattern)])],
      permisNumber: [this.user['driver_licenses_number'], Validators.required],
      permisDate: [this.user['driver_licenses_date'], Validators.required],
      country: [this.user['driver_licenses_country'], Validators.required]
    });

  }

  public submit() {

    if (this.form.status == 'INVALID') {

      this.toastr.show(
        'Certains champs sont invalides',
        'Formulaire mal rempli',
        {
          timeOut: 2500,
          closeButton: true,
        } as IndividualConfig,
        'toast-' + 'error'
      )

    } else {

      //verifier l'existance du telephone
      // this.loginService.CheckExistPhone(this.form.value.phone).subscribe(
      //   (data: any) => {
      //     this.item = data;
      //     if (this.item.status == true) {
      //       this.toastr.error('ce numero existe deja')

      //     } else {
            ///

            this.spinner.show();

            const regex = /[0-9]/gi;

            let message: string;

            if(this.form.value.message == null || this.form.value.message == undefined) {
              
              message = this.form.value.message;

            } else {

              message = this.form.value.message.replace(regex, 'X');

            }

            let renting: Renting = {
              'renting_date_departure': this.datePipe.transform(this.dataSearchService.templateSearchCar.dateStart, 'yyyy-MM-dd'),
              'renting_date_return': this.datePipe.transform(this.dataSearchService.templateSearchCar.dateEnd, 'yyyy-MM-dd'),
              'renting_hour_departure': this.form.value.hourStart,
              'renting_hour_return': this.form.value.hourEnd,
              'renting_number_day': this.dataSearchService.diffDate,
              'renting_price_location': this.dataSearchService.priceLocation,
              'renting_message': message,
              'loc_id': this.user.id,
              'vehicle_id': this.dataSearchService.idCar
            }

            let user = {
              'user_name': this.form.value.name,
              'user_surname': this.form.value.surname,
              'user_datenais': this.datePipe.transform(this.form.value.date, 'yyyy-MM-dd'),
              'user_phone': this.form.value.phone,
              'driver_licenses_number': this.form.value.permisNumber,
              'driver_licenses_date': this.datePipe.transform(this.form.value.permisDate, 'yyyy-MM-dd'),
              'driver_licenses_country': this.form.value.country,
            }

            this.rentingSubscription = this.rentingService.create(renting).subscribe((data: any) => {
              // console.log(data);

              this.rentingCreated = true;

              this.userSubscription = this.userService.updateUserRenting(this.user.id, user).subscribe((reponse: any) => {

                this.userUpdated = true;

                this.toastr.show(
                  'Votre demande de réservation a bien été enregistrée',
                  'Demande envoyée',
                  {
                    timeOut: 5000,
                    closeButton: true,
                  } as IndividualConfig,
                  'toast-' + 'success'
                )

                this.spinner.hide();

                this.dataSearchService.isRentingMap = false;

                this.router.navigate(['compte-utilisateur/mes-locations/locataire']);

              })

            })

      //     }

      //   },

      // );

    }

  }

  ngOnDestroy(): void {

    if (this.rentingCreated) {

      this.rentingSubscription.unsubscribe();

    }

    if (this.userUpdated) {

      this.userSubscription.unsubscribe();

    }
  }

}
