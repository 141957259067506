export class Restaurant {
    constructor(public id: number,
                public name: string,
                public address: string,
                public phone: string){}
}

export class Employee {
    constructor(public id: number,
                public image: string,
                public firstName: string,
                public lastName: string,
                public middleName: string,
                public email: string,
                public phone: string,
                public addresses: Address[], 
                public position: Position){}
}

//['General Manager','Assistant Manager'] ... https://aptito.com/blog/restaurant-positions-and-descriptions
export class Position {
    constructor(public id: number,
                public name: string){}
}

export class Address {
    constructor(public id: number,
                public country: Country, 
                public city: string,
                public place: string,
                public postalCode: string,
                public addressLine: string){}
}

export class Country {
    constructor(public name: string,
                public code: string){}
}

export class Customer {
    constructor(public id: number,
                public fullName: string, 
                public email: string,
                public phoneNumber: string,
                public address: string){}
}

export class Reservation {
    constructor(public id: number,
                public date: string,
                public time: string,
                public customer: Customer,
                public guests: number,
                public tableNumber: number,
                public status: ReservationStatus){}
} 

// Approved, Cancelled, Pending
export class ReservationStatus {
    constructor(public id: number,
                public name: string){}
}
 

export class Order {
    constructor(public id: number,
                public date: string,
                public items: MenuItem[],
                public quantity: number,
                public amount: number,
                public status: OrderStatus){}
}  

//Completed, Processing, On Hold, Refunded, Pending
export class OrderStatus {
    constructor(public id: number,
                public name: string){}
}

export class MenuItem {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public price: number,
    public image: MenuItemImage,
    public discount: number,
    public ratingsCount: number,
    public ratingsValue: number,
    public availibilityCount: number,
    public cartCount: number,
    public weight: number,
    public numberOfPlaces: number,
    public isVegetarian: boolean,
    public categoryId: number,
    public vehicle_energie: string,
    public vehicle_gearboxe: string,
    public title_image: string,
    public vehicle_mark: string,
    public vehicle_model: string,
    public note: number,
    public count_note: number,
    public vehicle_description: string,
    public vehicle_categorie: string,
    public vehicle_adress: string,
    public vehicle_price_location: number,
    public status: string,
    public vehicle_place_number: number,
    public vehicle_driver: number

  ) {}
}

export class MenuItemImage {
    constructor(public small: string,
                public medium: string,
                public big: string){ }
}

export class Category {
    constructor(public id: number,
                public name: string,
                public description: string){ }
}   

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number | null,
                public nextPage: number | null,
                public total: number,
                public totalPages: number){ }
}


//Models Galsen

export class TemplateSearchCar {

    constructor(
        public address: string,
        public geometry: object | null,
        public dateStart: Date | null,
        public dateEnd: Date | null,
        public periodStart: string | null,
        public periodEnd: string | null
    ) {}
}

export class Renting {

    constructor(
        public renting_date_departure: string | null,
        public renting_date_return: string | null,
        public renting_hour_departure: string,
        public renting_hour_return: string,
        public renting_number_day: number | null,
        public renting_price_location: number,
        public renting_message: string,
        public loc_id: number,
        public vehicle_id: number
    ) {}
}

export class Transfer {

    constructor(
        public transfer_amount: number,
        public transfer_type: string,
        public transfer_status: string  | null,
        public user_id: number,
        public created_at : any | null
    ) {}
}

export class Hour {

    constructor(
        public text: string,
        public value: string,
    ) {}
}

export class LoggedUser {

    constructor(
        public status: boolean,
        public currentTest: string,
    ) {}
}

export class Message {

    constructor(
        public 	message_text: string,
        public renting_id: number,
        public transmitter_id: number,
        public receiver_id: number,
        public key: string,
        public created_at: Date
    ) {}
}

export class Permission {

    constructor(
        public id : number,
        public 	permission_title: string,
        public permission_description: string
    ) {}
}

export class PermissionUser {

    constructor(
        public permission_id: any,
        public user_id : number
    ) {}
}

export class NumeroCompte {

    id: number;
    numero_compte_phone_number: string;
    numero_compte_operateur: string;
    user_id: number;

    constructor(numeroCompte: any) {
        this.id = numeroCompte.id || null,
        this.numero_compte_phone_number = numeroCompte.numeroCompte || null,
        this.numero_compte_operateur = numeroCompte.numero_compte_operateur || null,
        this.user_id = numeroCompte.user_id || null
    }
}

export class Extension {

    constructor(
        public extension_date_return: string,
        public extension_hour_return: string,
        public extension_number_day: number,
        public extension_price: number,
        public renting_id: number,
    ) {}
    
}