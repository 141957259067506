import { Injectable } from "@angular/core";

import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { HostService } from "./host.service";

@Injectable({
  providedIn: "root",
})
export class LoginserviceService {
  login(email: any) {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient, private getAuth: HostService) { }

  getAll(social: any) {
    return this.http.get(this.getAuth.gethttps() + "auth/" + social);
  }

  getuser(val: any) {
    //console.log(val);
    return this.http.post(this.getAuth.gethttps() + "login", val);
  }

  loginPhone(val: any) {
    return this.http.post(this.getAuth.gethttps() + "loginPhone", val);
  }
  
  // Sociallogin(val: any) {
  //   let obj = {
  //     val,
  //   };
  //   return this.http.post(this.getAuth.gethttps() + "Sociallogin", obj);
  // }
  resetpass(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "forgetPassword", val);
  }
  confirmeMailuser(val: any) {
  
    return this.http.post(this.getAuth.gethttps() + "confirmeMailuser", val);
  }
  confirmeMailuserChange(val: any) {
    let obj = {
      val,
    };
    return this.http.post(this.getAuth.gethttps() + "confirmeMailuserChange", obj);
  }

  verifcode(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "verifyCode", val);
  }

  MiseAjourValidationEmail(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "MiseAjourValidationEmail", val);
  }

  newpass(val: any) {
  
    return this.http.post(this.getAuth.gethttps() + "changePassword", val);
    
  }

  getinfouser() {
    return this.http.get(this.getAuth.gethttps() + "getuser");
  }

  createcmd(val: any) {
    let obj = {
      val,
    };
    return this.http.post(this.getAuth.gethttps() + "changePasswordUnik", obj);
  }

  changemail(val: any) {
    let obj = {
      val,
    };
    return this.http.post(this.getAuth.gethttps() + "changeEmail", obj);
  }

  updateUser(val: any, tabvaleur: any) {
    let obj = {
      email: val,
      tabvaleur: tabvaleur,
    };
    console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "updateUsers", obj);
  }

  //mise à jour du permis de conduire
  updatePermis(val: any, tabvaleur: any) {
    let obj = {
      email: val,
      tabvaleur: tabvaleur,
    };
    
    //console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "updatePermis", obj);
  }


   //mise à jour de la cni
   updateCni(val: any, tabvaleur: any) {
    let obj = {
      email: val,
      tabvaleur: tabvaleur,
    };
    
    //console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "updateCni", obj);
  }


  //mise a jour de la validation email
  updateEmailVerified(val: any, tabvaleur: any) {
    let obj = {
      val: val,
      tabvaleur: tabvaleur,
    };
    console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "updateEmailVerified", obj);
  }

  getinfoUser(email: any) {

    let obj = {
      email: email
    };

    return this.http.post(this.getAuth.gethttps() + "getinfoUser", obj);

  }

  CheckExistEmail(val: any) {
    let obj = {
      val,
    };
    return this.http.post(this.getAuth.gethttps() + "CheckExistEmail", obj);
  }

  CheckExistPhone(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "CheckExistPhone", val);

  }

  createnotif(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "createnotif", val);

  }

  findnotif(val: any) {
     
    let obj =  {
      id: val
    };

    return this.http.post(this.getAuth.gethttps() + "findnotif", obj);

  }

  createUtilisateurs(obj: any) {
    console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "createUtilisateurs", obj);
  }

  createUtilisateurs2(obj: any) {
    console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "createUtilisateurs2", obj);
  }
// serge 25032025
  createEnterprise(obj: any) {
    console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "createEnterprise", obj);
  }

  // creer user via email+password
  registerUser(val: any) {
    //console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "registerUser", val);
  }

  SendMailUtilisateurs(val: any) {
    //console.log(obj);
    return this.http.post(
      this.getAuth.gethttps() + "sendmailUtilisateurs",
      val
    );
  }

  verifyEmail(val: any) {
    //console.log(obj);
    return this.http.post(this.getAuth.gethttps() + "email/verify/" + val, val);
  }
  //return this.https.get(this.getHost. getHttpsEmail() + 'email/verify/'+idUser+'/');

  //google

  CreateUtilisateursGoogle(val: any) {
    return this.http.get(this.getAuth.gethttps() + "auth/google/callback", val);
  }


  /**
   * Déconnexion de l'utilisateur
   */
  logout() {

    return this.http.get(this.getAuth.gethttps() + "logout").pipe(
      catchError((error) => {

        return throwError(() => new Error('Erreur Erreur'));
        
      })
    );

  }

  //verifier champs permis et coordonnée
  VerifPermisEtCoordonnees(id: any) {
    let obj = {
      id,
    };
    return this.http.post(
      this.getAuth.gethttps() + "VerifPermisEtCoordonnees",
      obj
    );
  }

  /**
   * Retourner l'utilisateur connecté
  */
  findUser() {

    return this.http.get(this.getAuth.gethttps() + "userInfo");

  }
}


