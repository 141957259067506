<mat-card class="menu-item p-0" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
    [ngClass]="[viewType + '-item', 'column-'+column]">
    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
        <mat-chip-list *ngIf="menuItem.discount">
            <mat-chip color="warn" selected="true">{{menuItem.discount}}% OFF</mat-chip>
        </mat-chip-list>
        <div *ngIf="lazyLoad">
            <!-- <img src={{hostService.getHttpsImage()+menuItem.title_image}} class="w-100 d-block swiper-lazy" height="300"> -->
            <img src={{hostService.getHttpsImage()+menuItem.title_image}} class="vehicule_2 wiper-lazy">
        </div>
        <!-- <img *ngIf="!lazyLoad" src={{hostService.getHttpsImage()+menuItem.title_image}} class="w-100 d-block" height="300"> -->
        <img *ngIf="!lazyLoad" src={{hostService.getHttpsImage()+menuItem.title_image}} class="vehicule_2" >
    </div>
    <div fxFlex="100" class="p-3">
        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
            <mat-card-content class="mb-0">
                <h1 class="title">{{menuItem.vehicle_categorie}}</h1>
                <div>
                    <button class="item-button" mat-flat-button  matTooltip="Nombres de place" color="secondary">{{menuItem.vehicle_place_number}} <mat-icon>group</mat-icon></button>
                    <button class="item-button" mat-flat-button matTooltip="Moteur" color="secondary">M <mat-icon>account_tree</mat-icon></button>
                    <!-- <button class="item-button" mat-flat-button matTooltip="Energie" color="secondary">D <mat-icon>energy_savings_leaf</mat-icon></button> -->
                    <button class="item-button" mat-flat-button matTooltip="Climatisation" color="secondary">A/C <mat-icon>severe_cold</mat-icon></button>
                </div>
                <div>
                    <h1></h1>
                </div>
                <div class="mt-2 mb-2"> 
                    <div class="features">
                        <h3>{{menuItem.vehicle_adress}}</h3>
                        <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                            <span class="px-1"><mat-icon class="icon-desc">bookmarks</mat-icon> Marque</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ menuItem.vehicle_mark}}</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                            <span class="px-1"><mat-icon class="icon-desc">time_to_leave</mat-icon> Model</span>
                            <!-- <span class="px-1">Modèle:</span> -->
                            <span class="dots"></span>
                            <span class="fw-500">{{ menuItem.vehicle_model }}</span>
                        </p>
                        <p *ngIf="menuItem.vehicle_driver == 1" fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                            <span class="px-1"><mat-icon class="icon-desc">person</mat-icon> Avec Chauffeur</span>
                            <span class="dots"></span>
                            <span class="fw-500"> 
                                <mat-icon class="green-driver">done_outline</mat-icon> 
                            </span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                            <span class="px-1"><mat-icon class="icon-desc">task_alt</mat-icon> Disponibilité</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ menuItem.status }}</span>
                        </p>
                        <!-- <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1">
                            <span class="px-1">Ville:</span>
                            <span class="dots"></span>
                            <span class="fw-500">{{ menuItem.vehicle_adress}}</span>
                        </p>  -->
                    </div>
                </div>
            </mat-card-content>
            <div class="sticky-footer-helper" fxFlex="grow"></div>
            <mat-card-actions class="m-0 p-0">
               
                <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'"
                    class="mt-3">
                    <span class="text-muted">Avis Client<span *ngIf="viewType == 'list'" class="px-2">: {{menuItem.note}}</span></span>
                    <app-rating [ratingsCount]='menuItem.note' ratingsValue=2 [comment]='menuItem.count_note'></app-rating>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">

                    <h2 class="primary-color">
                        {{ menuItem.vehicle_price_location}} {{appService.getCurrency()}}
                    </h2>

                    <div class="text-muted">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                            <a (click)="clickCar(menuItem.id)" mat-raised-button color="primary" class="transform-none"
                                id="btnreserver">Reserver
                                <mat-icon>no_crash</mat-icon>
                            </a>

                        </div>
                    </div>
                </div>
            </mat-card-actions>
        </div>
    </div>
</mat-card>
