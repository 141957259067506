/**  
 * Valderane, 30/03/2025
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HostService {
  constructor() {}

  gethttps() {
   //return "http://localhost:8000/api/"
  //return "https://apirec.siloka.fr/api/";
  return "https://api.umexci.com/api/";

  }

  getHttpsImage() {
   //return "http://localhost:8000/storage/"
 // return "https://apirec.siloka.fr/storage/";
 return "https://api.umexci.com/storage/";
 

  }

  getHttpsEmail() {
    //return "http://localhost:8000/"
  //return "https://apirec.siloka.fr.com/";
  return "https://api.umexci.com/";

  }
  getHttpsUrl() {
    // return "http://localhost:4200/"
  //return "https://rec.siloka.fr/";
 // return "https://www.umexci.com/";
  // return "https://www.VoitureEntreParticuliers.com/";

  }
  getBlogUrl() {
    return "https://blog.VoitureEntreParticuliers.com/";
    }
  getAideUrl() {
      return "https://aide.VoitureEntreParticuliers.com/";
  }

  getCharacteristicCarUrl() {
    return "https://api.apiplaqueimmatriculation.com/get-vehicule-info";
  }
}
