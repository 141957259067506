<div fxLayout="row" class="p-2">
   
        <div fxFlex="100" fxFlex.gt-sm="100" fxFlex.sm="100">
            
            <button  mat-icon-button  color="primary" matTooltip="Affichage en Grille" (click)="changeViewType('grid')">
                <span><mat-icon>grid_view</mat-icon></span>
            </button>
            <button  mat-icon-button  color="primary" matTooltip="Affichage en Liste" (click)="changeViewType('list')">
                <span><mat-icon>format_list_bulleted</mat-icon></span>
            </button>
            <mat-button-toggle-group #controls="matButtonToggleGroup" appearance="legacy">            
                <mat-button-toggle value="search">
                    <mat-icon>search</mat-icon>
                </mat-button-toggle>           
            </mat-button-toggle-group> 
            <form class="user-search" [class.show]="controls.value == 'search'">
                <mat-form-field class="user-search-input">
                    <input matInput placeholder="Search location..." (keyup)="applyFilter($event)" name="search">
                </mat-form-field>
            </form>
        </div>
</div>
<mat-card class="m-2 p-0">
    <div class="table-wrapper" *ngIf="viewType==='list'"> 
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
        
            <!-- Number Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <td mat-cell *matCellDef="let renting; let i = index">#{{i+1}} </td>
            </ng-container>  

            <!-- Period of renting Column -->
            <ng-container matColumnDef="period_renting">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> D Départ - D Retour </th>
                <td mat-cell *matCellDef="let renting">
                    <span>Départ : 
                        <span class="period-location">{{renting.renting_date_departure}}</span>   
                        {{renting.renting_hour_departure}}<br>
                    </span> 
                       
                    <span>Retour : 
                        <span class="period-location">{{renting.renting_date_return}}</span>   
                        {{renting.renting_hour_return}}<br>
                    </span> 
                </td>
            </ng-container>  
            
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant </th>
                <td mat-cell *matCellDef="let renting">{{renting.renting_price_location}} {{currency}}</td>
            </ng-container>  

             <!-- Amount Owner Column -->
             <ng-container matColumnDef="amount_owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Montant a reversé </th>
                <td mat-cell *matCellDef="let renting">{{renting.price_to_be_paid}} {{currency}}</td>
            </ng-container>  

            <!-- Car Column -->
            <ng-container matColumnDef="car">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Véhicule </th>
                <td mat-cell *matCellDef="let renting">{{renting.vehicle_mark}} {{renting.vehicle_model}}</td>
            </ng-container> 

            <!-- status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
                <td mat-cell *matCellDef="let renting">
                    <span *ngIf="status == 'progress'">
                        <span *ngIf="renting.renting_date_departure <= currentDate! ; else elseBlock">En Cours</span>
                        <ng-template #elseBlock>Payée</ng-template> 
                    </span>
                    <span *ngIf="status == 'end'">Terminée</span>
                    <span *ngIf="status == 'cancel' || status == 'pending'">
                        <span *ngIf="renting.renting_status == 'REJETER'">Rejetée</span>
                        <span *ngIf="renting.renting_status == 'ANNULER'">Annulée</span>
                        <span *ngIf="renting.renting_status == 'EN_ATTENTE'">En Attente de Validation</span>
                        <span *ngIf="renting.renting_status == 'VALIDER'">En Attente de Paiement</span>
                    </span>
                </td>
            </ng-container> 

            <!-- Logder Column -->
            <ng-container matColumnDef="logder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Locataire </th>
                <td mat-cell *matCellDef="let renting">{{renting.logder_name}} {{renting.logder_surname}}</td>
            </ng-container>  

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Propriètaire </th>
                <td mat-cell *matCellDef="let renting">{{renting.user_name}} {{renting.user_surname}}</td>
            </ng-container>  

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let renting">{{ renting.created_at | date:"dd MMMM, yyyy 'à' HH:mm" }} </td>
            </ng-container> 
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let renting; columns: displayedColumns;"></tr>
        </table> 

        <p *ngIf="dataSource && dataSource.data.length === 0" class="py-5 mb-0 text-center">Aucune Location</p> 
        
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center" class="w-100 p-2" *ngIf="viewType==='grid'">
        <mat-card class="menu-item" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
            [ngClass]="[viewType + '-item', 'column-'+column]" fxFlex.gt-sm="30" fxFlex.sm="30" *ngFor="let item of rentings | paginate: { itemsPerPage: 10, currentPage: p }">
            <div fxFlex="100" class="p-3">
                <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
                    <mat-card-content class="mb-0">
                        <h1 class="title">{{ item.mark_lib}} {{ item.mod_lib }}</h1>
                        <p class="subtitle text-muted">
                            {{ item.created_at | date:"dd MMMM, yyyy 'à' HH:mm" }}
                        </p>
                        <div class="mt-2 mb-2"> 
                            <div class="features">
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">person_pin</mat-icon>Locataire</span>
                                    <span class="dots"></span>
                                    <span class="fw-500">{{item.logder_name}} {{item.logder_surname}}</span>
                                </p>
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">people_alt</mat-icon> Propriètaire                                    </span>
                                    <!-- <span class="px-1">Modèle:</span> -->
                                    <span class="dots"></span>
                                    <span class="fw-500">{{item.user_name}} {{item.user_surname}}</span>
                                </p>
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">event</mat-icon> Date départ</span>
                                    <span class="dots"></span>
                                    <span class="fw-500">{{ item.renting_date_departure | date |titlecase}}</span>
                                </p>
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">today</mat-icon> Date retour                                    </span>
                                    <!-- <span class="px-1">Modèle:</span> -->
                                    <span class="dots"></span>
                                    <span class="fw-500">{{item.renting_date_return| date |titlecase}}</span>
                                </p>
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">payments</mat-icon> Montant</span>
                                    <span class="dots"></span>
                                    <span class="fw-500">{{item.renting_price_location}} {{currency}}</span>
                                </p>
                                <p fxLayout="row" fxLayoutAlign="start center end" class="mb-1 desc-car">
                                    <span class="px-1"><mat-icon class="icon-desc">payments</mat-icon> Montant a reversé                                  </span>
                                    <!-- <span class="px-1">Modèle:</span> -->
                                    <span class="dots"></span>
                                    <span class="fw-500">{{item.price_to_be_paid}} {{currency}}</span>
                                </p>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="m-0 p-0">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                            <span class="fw-500" *ngIf="item.renting_status == 'EN_ATTENTE'" style="color: blue;">En attente de Validation</span>
                            <span class="fw-500" *ngIf="item.renting_status == 'VALIDER'" style="color: rgb(3, 154, 3);">Validée</span>
                            <span class="fw-500" *ngIf="item.renting_status == 'REJETER'" style="color:crimson;">Rejetée</span>
                            <span class="fw-500" *ngIf="item.renting_status == 'ANNULER'" style="color: red;">Annulée</span>
                            <span class="fw-500" *ngIf="item.renting_status == 'PAYER'" style="color: rgb(149, 157, 42);">
                                <span class="fw-500" *ngIf="item.renting_date_departure >= currentDate! ; else elseBlock">En Cours</span>
                                <ng-template #elseBlock>Payée</ng-template>    
                            </span>
                            <span class="fw-500" *ngIf="item.renting_status == 'TERMINER'" style="color: rgb(245, 153, 68);">Terminée</span>
                        </div>
                    </mat-card-actions>
                </div>
            </div>
        </mat-card>   
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="w-100 p-2">
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="20">
            <button  mat-icon-button  color="primary" matTooltip="Rafraichir" (click)="refresh()">
                <span><mat-icon>refresh</mat-icon> Mise à jour</span>
            </button>
        </div>
        
        <div fxFlex="100" fxFlex.gt-sm="80" fxFlex.sm="80" *ngIf="viewType==='grid'">
            <pagination-controls (pageChange)="p = $event" style="text-align: end;"></pagination-controls>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" class="{{viewType==='list' ? 'block' : 'hidden'}}" ></mat-paginator>
    </div>
    
</mat-card>