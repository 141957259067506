import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AppService } from 'src/app/app.service'; 
import { HostService } from 'src/app/services/host.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html' ,
  styleUrls: ['./toolbar1.component.scss']
})
export class Toolbar1Component implements OnInit {
  @Input() openSideNavResposive:MatSidenav; 

  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>(); 

  
  constructor(public appService:AppService,public getHost:HostService) { }

  ngOnInit() {
    
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

  
}