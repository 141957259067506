<mat-toolbar id="top-toolbar" class="main-content-header">
  <div
    class="theme-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <a class="logo" routerLink="/">
        <img [src]="getHost.getHttpsImage() + 'images/others/logo.png'" alt="" style="width: 60px !important;height: 60px !important;">
      </a>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" class="">
      <app-user-menu [openSideNavResposive]="openSideNavResposive"></app-user-menu>
    </div>
  </div>
</mat-toolbar>
